import { DayScheduledDiscount } from "./dayScheduledDiscount";
import { FuelDiscountCostValueType } from "./fuelDiscountCostValueType";

export class FuelDiscount {
  overlapsStandardDiscount: boolean | undefined;
  fuelId: string | undefined;
  discount: number | undefined;
  costValueType: FuelDiscountCostValueType | undefined;
  costValue: number | undefined;
  daysToExpire: number | undefined;
  amount: number | undefined;
  uniqueDiscountValue: boolean | undefined;

  dayScheduledDiscounts: DayScheduledDiscount[] = [];
  overlapsGroupDiscount: boolean | undefined;

  alterPriceType: string | undefined;
  alterPriceValueType: string | undefined;
  alterPriceValue: number | undefined;

  usageLimit: number | undefined;
  usageCount: number | undefined;
  intervalInHours: number | undefined;

  static createFuelDiscount(value: FuelDiscount) {
    return {
      fuelId: value.fuelId,
      alterPriceValue: value.alterPriceValue,
      alterPriceType: value.alterPriceType,
      alterPriceValueType: value.alterPriceValueType,
      costValueType: value.costValueType,
      costValue: value.costValue,
      daysToExpire: value.daysToExpire,
      uniqueDiscountValue: value.uniqueDiscountValue,
      amount: value.amount,
    };
  }

  static transform(value: FuelDiscount[], type: string) {
    if (type === 'default') {
      return value?.map(({ daysToExpire, ...fd }) => ({
        ...fd,
        costValueType: FuelDiscountCostValueType.points,
      })) as FuelDiscount[];
    }
    return value;
  }

  constructor(init?: Partial<FuelDiscount>) {
    Object.assign(this, init);
  }
}