import { Box } from '@mui/material';
import React from 'react';
import { ArrayInput, FormDataConsumer, minValue, required, SelectInput, SimpleFormIterator, TextInput } from 'react-admin';
import { AttendantGoalType } from '../../../models';

const FormTarget: React.FC<{ formData?: any }> = ({ formData }) => {

  return (
    <>
      <ArrayInput source="targets" label="Metas">
        <SimpleFormIterator disableReordering>
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource }) => {
              const typesInUse = (formData.targets || []).map(target => target.type).filter(type => type);
              const availableTypes = AttendantGoalType.attendantGoalTypesChoices.filter(choice => !typesInUse.includes(choice.id) || choice.id === scopedFormData?.type);
              return (
                <Box display="flex" flexDirection="row" gap={2}>
                  <SelectInput
                    source={getSource('type')}
                    label="Tipo"
                    choices={availableTypes}
                    style={{ minWidth: 200 }}
                    validate={[required()]}
                  />
                  <TextInput
                    source={getSource('minimumAmount')}
                    label="Mínimo"
                    format={value => !value ? '0,00' : `${Number(value).toFixed(2)}`}
                    parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
                    style={{ minWidth: 200 }}
                    validate={[required(), minValue(0.01)]}
                  />
                  <TextInput
                    source={getSource('fixedPrice')}
                    label="R$ fixo"
                    format={value => !value ? 'R$ 0,00' : `R$ ${Number(value).toFixed(2)}`}
                    parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
                    style={{ minWidth: 200 }}
                  />
                  <TextInput
                    source={getSource('additionalUnit')}
                    label="Unid. adicional"
                    format={value => !value ? '0,00' : `${Number(value).toFixed(2)}`}
                    parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
                    style={{ minWidth: 200 }}
                  />
                  <TextInput
                    source={getSource('additionalPrice')}
                    label="R$ adicional"
                    format={value => !value ? 'R$ 0,00' : `R$ ${Number(value).toFixed(2)}`}
                    parse={value => !value ? 0 : +value.replace(/[^\d]/g, '') / 100}
                    style={{ minWidth: 200 }}
                  />
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
}

export default FormTarget;