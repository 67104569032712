import { groupBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  Filter,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextField,
  useDataProvider,
  useListContext,
} from 'react-admin';
import { BetweenDatesTimesInput } from '../../components/BetweenDatesInput';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId, getUserId } from '../../lib';

const printCell = (record) => ({
  '@media print': {
    padding: 1,
    margin: 1,
    '& span': {
      fontSize: 9,
      color: '#000000'
    },
    borderBottomColor: '#000000',
  },
});

const ReportPartnerValuesList = ({ Panel }) => {
  const props = useListContext();

  let data = [], summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = [reportInfo.summaryData];
    }
  }

  return (
    <>
      <SummaryData />
      <Datagrid bulkActionButtons={false} rowSx={printCell} data={data}>
        <ReferenceField label="Parceiro" source="id" reference="places" link={false} sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="products" label="Produtos" sortable={false}>
          <Datagrid bulkActionButtons={false} >
            <ReferenceField label="Produtos" emptyText="Cashback" source="id" reference="chains/chainId/products" link={false} sortable={false}>
              <TextField source="name" />
            </ReferenceField>
            <NumberField
              source="partner_value"
              label="Valor"
              options={{ style: 'currency', currency: 'BRL' }}
              textAlign="center"
              sortable={false}
            />
          </Datagrid>
        </ArrayField>
        <NumberField
          source="partner_value"
          label="Valores"
          options={{ style: 'currency', currency: 'BRL' }}
          textAlign="center"
          sortable={false}
        />
      </Datagrid>
    </>
  )
}

const partnerValuesExporter = async (data, fetchRelatedRecords) => {
  let reportInfo = data[0].data;

  reportInfo = [].concat(...reportInfo.map(item => item.products.map(p => ({
    id: item.id,
    partner_value: item.partner_value,
    product_id: p.id,
    product_value: p.partner_value,
    place_id: item.place_id,
  }))));

  const relations = [
    { field: 'id', resource: 'places' },
    { field: 'place_id', resource: 'chains/chainId/places' },
    { field: 'product_id', resource: 'chains/chainId/products' },
  ];

  const fetchData = await fetchRelatedRecords(relations, reportInfo);

  const getData = (resource) => {
    return fetchData.find(({ resource: res }) => res === resource)?.results || [];
  }

  reportInfo = reportInfo.map(item => {
    const { id: itemId, partner_value: valor_total, place_id, product_id, product_value } = item;
    const place = getData('places').find(({ id }) => id === itemId);
    const product = getData('chains/chainId/products').find(({ id }) => id === product_id);
    const filterPlace = getData('chains/chainId/places').find(({ id }) => id === place_id);

    return {
      local: place ? place?.name : 'Não identificado',
      valor_total,
      produto: product ? product.name : 'Não identificado',
      valor_produto: product_value,
      filter_place: filterPlace ? filterPlace.name : 'Não identificado',
    }
  });

  reportInfo = groupBy(reportInfo, 'local');
  reportInfo = Object.keys(reportInfo).map(key => {
    const item = reportInfo[key];
    return {
      local_pagador: key,
      local_recebedor: item[0].filter_place,
      valor_total: item[0].valor_total,
      produtos: item.map(i => ({ nome: i.produto, valor: i.valor_produto })),
    };
  });

  return reportInfo;
}

const SummaryData = () => {
  const props = useListContext();

  let data = [], summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = [reportInfo.summaryData];
    }
  }

  return (
    <Datagrid bulkActionButtons={false} rowSx={printCell} style={{ marginBottom: 60 }} data={summaryData} total={1}>
      <NumberField
        source="total_cashback"
        label="Total em Cashback Bruto"
        emptyText="R$ 0,00"
        options={{ style: 'currency', currency: 'BRL' }}
        textAlign="center"
        sortable={false}
      />
      <NumberField
        source="net_total_cashback"
        label="Total em Cashback Líquido"
        emptyText="R$ 0,00"
        options={{ style: 'currency', currency: 'BRL' }}
        textAlign="center"
        sortable={false}
      />
      <NumberField
        source="total_product"
        label="Total em Produtos Bruto"
        emptyText="R$ 0,00"
        options={{ style: 'currency', currency: 'BRL' }}
        textAlign="center"
        sortable={false}
      />
      <NumberField
        source="net_total_product"
        label="Total em Produtos Líquido"
        emptyText="R$ 0,00"
        options={{ style: 'currency', currency: 'BRL' }}
        textAlign="center"
        sortable={false}
      />
      <NumberField
        source="total_value"
        label="Total"
        emptyText="R$ 0,00"
        options={{ style: 'currency', currency: 'BRL' }}
        textAlign="center"
        sortable={false}
      />
    </Datagrid>
  )
}

const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  filterValues.userId = getUserId();

  return (
    <>
      <Datagrid bulkActionButtons={false} rowSx={printCell} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
        <ReferenceField label="Usuário" source="userId" reference="employees" emptyText="Não definido" textAlign="center" filter={{ type: 'place' }}>
          <TextField source="login" />
        </ReferenceField>
      </Datagrid>
      <Datagrid bulkActionButtons={false} rowSx={printCell} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
        <DateField source="from" textAlign="center" label="De" showTime sortable={false} />
        <DateField source="to" textAlign="center" label="Até" showTime sortable={false} />
        <DateField source="generatedReportAt" textAlign="center" label="Gerado" showTime sortable={false} />
      </Datagrid>
    </>
  );
}

const ReportFilters = (props) => {
  const { filterValues, setFilters } = useListContext();
  const dataProvider = useDataProvider();

  const [employees, setEmployees] = React.useState([]);
  const [place, setPlace] = React.useState("");

  const searchEmployees = (placeSelected) => {
    if (placeSelected) {
      dataProvider.getList('employees', { sort: { field: 'name', order: 'ASC' }, filter: { placeId: placeSelected, type: 'place' } })
        .then(res => {
          const dataFormated = res.data.map((e) => ({ "id": e.id, "name": e.name }))
          const employeesIds = dataFormated.length ? dataFormated : [{ "id": null, "name": "Todos" }]
          setEmployees(employeesIds);
        })
    } else {
      delete filterValues.partnerId;
      setEmployees([]);
    }
    delete filterValues.employeeIds;
    filterValues.partnerId = placeSelected;

    setFilters(filterValues)
    setPlace(placeSelected);
  }
  return (
    <Filter {...props}>
      <BetweenDatesTimesInput format={'YYYY-MM-DD HH:mm'} alwaysOn />
      {!getPlaceId()
        ? <ReferenceInput
          source="partnerId"
          reference="places"
          filter={{ chainId: getChainId() }}
          perPage={null}
          sort={{ field: "name", order: "ASC" }}
          alwaysOn
        >
          <SelectInput
            onChange={e => searchEmployees(e.target.value)}
            label="Local"
            emptyText="Todos"
            style={{ minWidth: 180 }}
            optionText={'name'} />
        </ReferenceInput>
        : null
      }
      {!getPlaceId() && place
        ? <SelectArrayInput
          label="Funcionários"
          source='employeeIds'
          choices={employees}
          style={{ minWidth: 180 }}
          alwaysOn
        />
        : null
      }
    </Filter>
  );
}


const ReportPartnerValues = (props) => {
  return (
    <Permission permission={PERMISSIONS.PARTNER_VALUES_REPORT}>
      <ReportList
        {...props}
        basePath="report-partner-values"
        title="Relatório de valores por parceiros"
        resource="chains/chainId/reports/partner-values"
        filters={<ReportFilters />}
        sort={{ field: 'id', order: 'ASC' }}
        perPage={999999}
        showPagination={false}
        filterDefaultValues={{
          from: moment().startOf('day').toISOString(),
          to: moment().endOf('day').toISOString(),
          partnerId: getPlaceId(),
          employeeIds: getPlaceId() ? [getUserId()] : null
        }}
        bulkActionButtons={false}
        customExporter={partnerValuesExporter}
        filterTablePrint={<FilterTablePrint />}
        showFilterLabel={false}
        landscape={false}
        disableSummaryPrint={false}
        titleOnPrint={"Relatório de valores por parceiros"}
        noPadding
        printContent={() => (
          <>
            <FilterTablePrint />
            <SummaryData />
          </>
        )}
      >
        <ReportPartnerValuesList />
      </ReportList>
    </Permission >
  );
};

export default ReportPartnerValues;