import React, { useCallback, useState } from 'react';
import { Create, useDataProvider, useNotify, useRedirect } from 'react-admin';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { CheckChainUsesModule, ModuleKey } from '../../components';
import WizardForm from '../../components/WizardForm';
import FormFrameInfo from './wizardForms/FormFrameInfo';
import FormSchedule from './wizardForms/FormSchedule';
import dayjs from 'dayjs';
import FormStationAndFuel from './wizardForms/FormStationAndFuel';
import FormTarget from './wizardForms/FormTarget';
import { AttendantGoal } from '../../models';
import { getChainId } from '../../lib';
import { useLocation } from 'react-router';

const AttendantTargetCreate: React.FC = () => {

    const { state: params }: { state: Object } = useLocation();
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [completedSteps, setCompletedSteps] = React.useState<{
        [k: number]: boolean;
    }>({});

    const chainId = getChainId();

    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleConcluedStep = (index) => {
        setCompletedSteps(value => ({ ...value, [index]: true }));
    }

    const getInitialValues = useCallback(() => {
        const attendantGoalBoard = AttendantGoal.createAttendantGoalBoardClone(params as any || {});

        return { ...attendantGoalBoard };
    }, []);

    const validateFrameInfo = (formData: any) => {
        if (!formData.name) {
            return { error: true, message: 'Campo nome do quadro está vazio' };
        }
        return { error: false };
    }

    const handleFrameInfo = (formData: any, nextStep: (index?: number) => void) => {
        nextStep();
    }

    const validateSchedule = (formData: any) => {
        if (!formData.startDate || !formData.endDate) {
            return { error: true, message: 'Defina uma data de início e fim' };
        } else if (!dayjs(formData.startDate).isValid() || !dayjs(formData.endDate).isValid()) {
            return { error: true, message: 'Defina uma data válida' };
        } else if (dayjs(formData.endDate) <= dayjs(formData.startDate)) {
            return { error: true, message: 'Data final não pode ser inferior a data inicial' };
        }

        if (!formData.daysOfWeek || formData.daysOfWeek.length === 0) {
            return { error: true, message: 'Campo de dias da semana está vazio' };
        }

        return { error: false };
    }

    const handleShedule = (formData: any, nextStep: (index?: number) => void) => {
        nextStep();
    }

    const validateStationAndFuel = (formData: any) => {
        if ((!formData.stationIds || !formData.stationIds?.length) && !formData.selectsAllPlaces) {
            return { error: true, message: 'Campo de postos está vazio' };
        }

        if ((!formData.fuelIds || !formData.fuelIds?.length) && !formData.selectsAllFuels) {
            return { error: true, message: 'Campo de combustíveis está vazio' };
        }

        return { error: false };
    }

    const handleStationAndFuel = (formData: any, nextStep: (index?: number) => void) => {
        nextStep();
    }

    const validateTarget = (formData: any) => {
        if (!formData.targets || formData.targets?.length === 0) {
            return { error: true, message: 'Nenhuma meta foi definida' };
        }

        if ((!formData.fuelIds || !formData.fuelIds?.length) && !formData.selectsAllFuels) {
            return { error: true, message: 'Campo de combustíveis está vazio' };
        }

        return { error: false };
    }

    const handleCreateAttendantGoalboard = (formData: any) => {
        setIsloading(true);
        dataProvider.create('attendant-goal-boards', {
            data: AttendantGoal.createAttendantGoalBoard({ ...formData, chainId }),
        }).then(response => {
            if (!response.data.id) { throw null; }
            notify('Quadro criado com sucesso');
            redirect('/attendant-goal-boards');
        }).catch(error => {
            notify(error?.message || 'Erro ao criar quadro', { type: 'warning' });
        }).finally(() => {
            setIsloading(false);
        });
    }

    return (
        <Permission permission={PERMISSIONS.ATTENDANT_TARGET}>
            <CheckChainUsesModule module={ModuleKey.ATTENDANT_TARGET}>
                <Create title='Cadastrar quadro'>
                    <WizardForm
                        sanitizeEmptyValues
                        isLoading={isLoading}
                        completedSteps={completedSteps}
                        defaultValues={getInitialValues()}
                        handleConcluedStep={handleConcluedStep}
                        formSteps={[
                            {
                                label: "Quadro",
                                component: <FormFrameInfo />,
                                validate: validateFrameInfo,
                                onNextFunction: handleFrameInfo,
                            },
                            {
                                label: "Agendamento",
                                component: <FormSchedule />,
                                validate: validateSchedule,
                                onNextFunction: handleShedule,
                            },
                            {
                                label: "Postos e Combustíveis",
                                component: <FormStationAndFuel />,
                                validate: validateStationAndFuel,
                                onNextFunction: handleStationAndFuel,
                            },
                            {
                                label: "Metas",
                                component: <FormTarget />,
                                validate: (formData) => {
                                    for (const validate of [validateFrameInfo, validateSchedule, validateStationAndFuel, validateTarget]) {
                                        const result = validate(formData);
                                        if (result.error === true) {
                                            return result;
                                        }
                                    }
                                    return { error: false };
                                },
                                onNextFunction: handleCreateAttendantGoalboard,
                            },
                        ]}
                    />
                </Create>
            </CheckChainUsesModule>
        </Permission>
    );
};

export default AttendantTargetCreate;