import { TargetAttendantGoalBoard } from "./targetAttendantGoalBoard";

export enum AttendantGoalBoardDaysOfWeek {
  Holiday = 'holiday',
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export namespace AttendantGoalBoardDaysOfWeek {
  export const translateType = (type: AttendantGoalBoardDaysOfWeek): string => {
    switch (type) {
      case AttendantGoalBoardDaysOfWeek.Holiday:
        return "Feriado"
      case AttendantGoalBoardDaysOfWeek.Sunday:
        return "Domingo";
      case AttendantGoalBoardDaysOfWeek.Monday:
        return "Segunda";
      case AttendantGoalBoardDaysOfWeek.Tuesday:
        return "Terça";
      case AttendantGoalBoardDaysOfWeek.Wednesday:
        return "Quarta";
      case AttendantGoalBoardDaysOfWeek.Thursday:
        return "Quinta";
      case AttendantGoalBoardDaysOfWeek.Friday:
        return "Sexta";
      case AttendantGoalBoardDaysOfWeek.Saturday:
        return "Sabado";
      default: return "";
    };
  };

  export const attendantGoalBoardDaysOfWeekChoices = Object.values(AttendantGoalBoardDaysOfWeek).filter(x => typeof x === "string")
    .map(type => {
      return ({
        id: type,
        name: AttendantGoalBoardDaysOfWeek.translateType(type as any)
      })
    });
};

export class AttendantGoal {

  id: string | undefined;
  chainId: string | undefined;
  name: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  daysOfWeek?: AttendantGoalBoardDaysOfWeek[] | undefined;
  stationIds: Array<string> | undefined;
  fuelIds: Array<string> | undefined;
  selectsAllPlaces?: boolean | undefined;
  selectsAllFuels?: boolean | undefined;
  scheduleSunday?: boolean | undefined;
  scheduleMonday?: boolean | undefined;
  scheduleTuesday?: boolean | undefined;
  scheduleWednesday?: boolean | undefined;
  scheduleThursday?: boolean | undefined;
  scheduleFriday?: boolean | undefined;
  scheduleSaturday?: boolean | undefined;
  scheduleHoliday?: boolean | undefined;
  targets: TargetAttendantGoalBoard[] | undefined;

  static convertDaysOfWeekToBoolean(daysOfWeek: AttendantGoalBoardDaysOfWeek[]) {
    return Object.values(AttendantGoalBoardDaysOfWeek).filter(value => typeof value === 'string').reduce((acc, day) => {
      acc[`schedule${day.charAt(0).toUpperCase()}${day.slice(1)}`] = daysOfWeek.includes(day as any);
      return acc;
    }, {} as Record<string, boolean>);
  }

  static convertScheduleBooleanToDaysOfWeek(schedule: any): AttendantGoalBoardDaysOfWeek[] {
    return Object.entries(schedule)
      .filter(([key, value]) => value && key.startsWith("schedule"))
      .map(([key]) => key.replace("schedule", "").toLowerCase())
      .filter(day => Object.values(AttendantGoalBoardDaysOfWeek).includes(day as AttendantGoalBoardDaysOfWeek))
      .map(day => day as AttendantGoalBoardDaysOfWeek);
  }

  static createAttendantGoalBoard(data: AttendantGoal) {

    const { daysOfWeek, ...payload } = data;

    let attendantGoal: Omit<AttendantGoal, 'targets' | 'daysOfWeek'> & { targets: Omit<TargetAttendantGoalBoard, 'id'>[] | undefined } = {
      ...payload,
      targets: payload.targets?.map(target => TargetAttendantGoalBoard.createAttendantTarget(target)),
    }

    if (daysOfWeek) {
      attendantGoal = { ...attendantGoal, ...this.convertDaysOfWeekToBoolean(daysOfWeek) };
    }

    return attendantGoal;
  }

  static createAttendantGoalBoardClone(data: AttendantGoal) {

    const {
      name,
      startDate,
      endDate,
      stationIds,
      fuelIds,
      scheduleSunday,
      scheduleMonday,
      scheduleTuesday,
      scheduleWednesday,
      scheduleThursday,
      scheduleFriday,
      scheduleSaturday,
      scheduleHoliday,
      ...payload
    } = data;

    let attendantGoalClone: Omit<AttendantGoal, 'id' | 'targets' | 'chainId'> & { targets: Omit<TargetAttendantGoalBoard, 'id'>[] | undefined } = {
      name,
      startDate,
      endDate,
      stationIds,
      fuelIds,
      targets: payload.targets?.map(target => TargetAttendantGoalBoard.createAttendantTarget(target)),
    }

    attendantGoalClone.daysOfWeek = this.convertScheduleBooleanToDaysOfWeek({
      scheduleSunday,
      scheduleMonday,
      scheduleTuesday,
      scheduleWednesday,
      scheduleThursday,
      scheduleFriday,
      scheduleSaturday,
      scheduleHoliday,
    });

    return attendantGoalClone;
  }

  constructor(init?: Partial<AttendantGoal>) {
    Object.assign(this, init);
  }
}