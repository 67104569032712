import React from 'react';
import { Button, useDataProvider, useNotify, useRecordContext, useRedirect } from 'react-admin';

interface CloneAttendantGoalBoardButtonProp {
  record?: any;
  variant?: 'text' | 'outlined' | 'contained';
  style?: any;
}

const CloneAttendantGoalBoardButton: React.FC<CloneAttendantGoalBoardButtonProp> = (props) => {
  const record = useRecordContext();
  if (!record) { return null; }

  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleOnClick = (event: any) => {
    event.stopPropagation();

    dataProvider.getOne('attendant-goal-boards', { id: record.id })
      .then(({ data }) => {
        redirect('/attendant-goal-boards/create', undefined, undefined, undefined, data);
      })
      .catch((err) => {
        notify('Não foi possível realizar a operação, tente novamente');
      });
  }

  return (
    <>
      <Button {...props} onClick={handleOnClick} variant='outlined' label="Clonar" />
    </>
  );
}

export default CloneAttendantGoalBoardButton;