import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  minValue,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { RequiredChain, Tooltip } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';


const MininumAmountRule = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const [fuels, setFuels] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('fuels', { sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: false }, filter: { chainId: getChainId() } })
      .then(({ data }) => {
        setFuels(data);
      })
  }, [getChainId()]);

  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    refresh();
  };

  const onError = (error) => {
    notify('ra.notification.data_provider_error', { type: 'error' });
    navigate('/minimum-amount-rule-edit');
  };

  const transform = (data) => {
    return {
      minimumAmountRule: data.minimumAmountRule.map((item) => {
        const { id, employeeId, ...itemProps } = item;
        return { ...itemProps, chainId: data.id }
      })
    };
  }

  const ToolbarCustom = () => {
    return (
      <Toolbar>
        <SaveButton />
      </Toolbar>
    );
  }

  return (
    <Permission permission={PERMISSIONS.MINIMUM_AMOUNT_RULE}>
      <RequiredChain>
        <Edit id={getChainId()} title="Quantidade Mínima" resource="minimum-amount-rule" mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }} transform={transform}>
          <SimpleForm toolbar={<ToolbarCustom />} >
            <FormDataConsumer>
              {({ formData }) => {
                const disableAddFuel = formData?.minimumAmountRule ? formData.minimumAmountRule.length === fuels.length : false
                return (
                  <ArrayInput label="" source="minimumAmountRule">
                    <SimpleFormIterator disableAdd={disableAddFuel} disableClear disableRemove={(record) => !!(record && record.id)} disableReordering fullWidth>
                      <FormDataConsumer>
                        {({ formData: formMinimumAmountRule, scopedFormData, getSource }) => {
                          const selectedFuelIds = formMinimumAmountRule.minimumAmountRule.map(mr => mr.fuelId);
                          const availableChoices = (fuels || []).filter(choice => !selectedFuelIds.includes(choice.id) || scopedFormData.fuelId === choice.id);
                          return (
                            getSource && (
                              <Stack direction="row" spacing={2} alignItems="baseline">
                                <SelectInput
                                  label="Combustível"
                                  source={getSource("fuelId")}
                                  choices={availableChoices}
                                  optionText="name"
                                  optionValue="id"
                                  isRequired
                                  inputProps={scopedFormData?.id ? { disabled: true } : { disabled: false }}
                                  sx={{ minWidth: 300 }}
                                />
                                <TextInput
                                  source={getSource("amount")}
                                  label="Litros (Mínimo)"
                                  format={value => (!value ? '0,00' : `${Number(value).toFixed(2)}`)}
                                  parse={value => (!value ? 0 : +value.replace(/[^\d]/g, '') / 100)}
                                  validate={minValue(1)}
                                />
                                <Tooltip title="A quantidade de pontos que o cliente vai ganhar, além da pontuação padrão/por grupo. Colocando 2, significa pontos em dobro."
                                  sxContainer={{ alignItems: 'flex-start' }} placement="top-start">
                                  <TextInput
                                    source={getSource("factor")}
                                    label="Fator"
                                    format={value => (!value ? '0,00' : `${Number(value).toFixed(2)}`)}
                                    parse={value => (!value ? 1 : +value.replace(/[^\d]/g, '') / 100)}
                                    validate={minValue(1)}
                                  />
                                </Tooltip>
                                <BooleanInput label="Habilitar" source={getSource("isActive")} defaultValue={false} />
                              </Stack>
                            )
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Edit>
      </RequiredChain>
    </Permission>
  );
};

export default MininumAmountRule;