import { Button, useNotify, useRecordContext, useRefresh, useUpdate } from "react-admin";

const CustomButton = () => {
  const refresh = useRefresh();
  const [update, { isLoading }] = useUpdate();
  const notify = useNotify()
  const record = useRecordContext();
  if (!record) { return null; }

  const handleClick = () => {
    update("device", { id: record.id, data: { state: "inactive" }, previousData: { ...record } }, {
      onSuccess: () => {
        notify("Dispositivo desvinculado", { type: "info" });
        refresh();
      },
      onError: () => {
        notify("Algo deu errado", { type: "warning" });
      }
    });
  }

  return (
    <Button label="Desvincular" onClick={handleClick} disabled={isLoading} variant="outlined" />
  )
}


export default CustomButton;
