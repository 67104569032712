import React from 'react';
import Alert from '@mui/material/Alert';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
  FormDataConsumer,
  TopToolbar,
  ShowButton,
  Toolbar,
  minValue,
  DateTimeInput,
  required,
} from "react-admin";
import { PriceInput, SaveConfirmButton } from '../../components';
import { FillinOrigin } from '../../models';
import moment from 'moment';
import { formatAttendantLabel, formatNumber } from '../../utils';

const transform = (data) => {
  const { place, fuel, sale, ...rest } = data;
  return rest;
}

const FillinEdit = () => {

  const EditActions = () => (
    <TopToolbar>
      <ShowButton />
    </TopToolbar>
  );

  const CustomToolbar = () => (
    <Toolbar>
      <FormDataConsumer>
        {({ formData }) => (
          <SaveConfirmButton
            formData={formData}
            validate={(data) => {
              if (data.pricePerLiter > 10 || data.pricePerLiter < 1) {
                return true;
              }
              return false
            }}
            contentMessage={
              <Alert
                variant="outlined"
                severity="warning"
              >
                O preço por litro é <strong>{formatNumber(formData.pricePerLiter, { style: 'currency', currency: 'BRL', minimumFractionDigits: 3, maximumFractionDigits: 3 })}</strong> <br/>
                Este valor parece incorreto. Tem certeza que deseja continuar?
              </Alert>
            }
          />
        )}
      </FormDataConsumer>
    </Toolbar>
  );

  return (
    <Edit title="Editar Abastecimneto" transform={transform} redirect={"show"} actions={<EditActions />}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <ReferenceInput reference="chains" source="chainId">
          <SelectInput
            label="Rede"
            optionText="name"
            sx={{ minWidth: 220 }}
            disabled
          />
        </ReferenceInput>
        <ReferenceInput reference="customers" source="customerId">
          <SelectInput
            label="Cliente"
            optionText="name"
            sx={{ minWidth: 220 }}
            disabled
          />
        </ReferenceInput>
        <NumberInput source="amount" label="Quantidade em litros" validate={[minValue(0.01)]} />
        <PriceInput source="value" label="Valor pago" locales="pt-BR" validate={[minValue(0.01)]} />
        <PriceInput source="pricePerLiter" label="Preço por litro" precision={3} locales="pt-BR" validate={[minValue(0.001)]} />
        <PriceInput source="oldPricePerLiter" label="Preço por litro original" precision={3} locales="pt-BR" validate={[minValue(0.001)]} />
        <NumberInput source="hoseNumber" label="Número do bico" />
        <NumberInput source="encerrante" label="Encerrante" />
        <SelectInput
          source="origin"
          label="Origem"
          choices={FillinOrigin.fillinOriginChoices}
          sx={{ minWidth: 220 }}
          isRequired
        />
        <ReferenceInput
          reference="fuels"
          source="fuelId"
          perPage={null}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput
            optionText="name"
            label="Combustível"
            sx={{ minWidth: 220 }}
            isRequired
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              reference="stations"
              source="placeId"
              sort={{ field: 'name', order: 'ASC' }}
              filter={{ chainId: formData.chainId }}
              perPage={null}
            >
              <SelectInput
                optionText="name"
                label="Posto"
                sx={{ minWidth: 220 }}
                isRequired
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              emptyText="Não identificado"
              source="attendantId"
              reference="attendants"
              sort={{ field: 'name', order: 'ASC' }}
              filter={{ chainId: formData.chainId }}
              perPage={null}
            >
              <SelectInput
                optionText={record => formatAttendantLabel(record)}
                label="Frentista"
                sx={{ minWidth: 220 }}
                isRequired
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <DateTimeInput
          source="createdAt"
          label="Data do concentrador"
          locales="pt-BR"
          parse={(value) => moment(value).toISOString()}
          validate={required()}
        />
        <DateTimeInput
          source="receivedAt"
          label="Data de recebimento"
          locales="pt-BR"
          parse={(value) => moment(value).toISOString()}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export default FillinEdit;