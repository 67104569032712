import { AttendantGoalType } from "./attendantGoalType";

export class TargetAttendantGoalBoard {

  id: string | undefined;
  attendantGoalBoardId?: string | undefined;
  type: AttendantGoalType | undefined;
  minimumAmount: number | undefined;
  fixedPrice: number | undefined;
  additionalUnit: number | undefined;
  additionalPrice: number | undefined;

  static createAttendantTarget(data: TargetAttendantGoalBoard) {
    const { id, attendantGoalBoardId, ...payload } = data;
    let attendantTarget: Omit<TargetAttendantGoalBoard, 'id'> = {
      ...payload,
    }

    return attendantTarget;
  }

  constructor(init?: Partial<TargetAttendantGoalBoard>) {
    Object.assign(this, init);
  }
}