import { Box, Typography } from '@mui/material';
import React from 'react';
import { BooleanInput, ReferenceArrayInput, required, SelectArrayInput, TextInput, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../../constants';

const FormStationAndFuel: React.FC<{ formData?: any }> = ({ formData }) => {

  const { permissions } = usePermissions();

  return (
    <>
      {
        (!formData.chainId && (permissions && permissions.includes(PERMISSIONS.ADMIN))) &&
        <Typography>* Selecione uma rede na barra de ferramentas acima</Typography>
      }
      <Box style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
        {
          !formData.selectsAllPlaces &&
          <ReferenceArrayInput
            style={{ minWidth: 260 }}
            sort={{ field: 'name', order: 'ASC' }}
            //@ts-ignore
            perPage={null}
            source="stationIds"
            reference="stations"
            isRequired
          >
            <SelectArrayInput label="Postos" optionText="name" validate={[required()]} />
          </ReferenceArrayInput>
        }
        <BooleanInput
          source="selectsAllPlaces"
          label="Selecionar todos os postos"
        />
      </Box>

      <Box style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
        {
          !formData.selectsAllFuels &&
          <ReferenceArrayInput
            style={{ minWidth: 260 }}
            sort={{ field: 'name', order: 'ASC' }}
            filter={formData.selectsAllPlaces ? { chainId: formData.chainId } : formData.stationIds?.length ? { placeIds: formData.stationIds } : {}}
            //@ts-ignore
            perPage={null}
            source="fuelIds"
            reference="fuels"
            isRequired
          >
            <SelectArrayInput label="Combustíveis" optionText="name" validate={[required()]} disabled={!formData.selectsAllPlaces && !formData.stationIds?.length} />
          </ReferenceArrayInput>
        }
        <BooleanInput
          source="selectsAllFuels"
          label="Selecionar todos os combustíveis"
        />
      </Box>
    </>
  );
}

export default FormStationAndFuel;