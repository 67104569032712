import { ComponentType } from 'react'
import PlacesUsers from '././placesUsers'
import UnifiedSend from './UnifiedSend'
import advertisements from './advertisements'
import attendantUsers from './attendantUsers'
import Attendants from './attendants'
import BillingAlert from './billingAlert'
import ExpirationAlert from './expirationAlerts'
import Billings from './billings'
import BillingsPayment from './billingsPayment'
import Chains from './chains'
import Clients from './clients'
import CompaniesWithoutPoints from './companiesWithoutPoints'
import CouponRefund from './couponRefund'
import CustomerGroup from './customerGroup'
import discounts from './discounts'
import DispatchMessage from './dispatchMessage'
import DrawingRule from './drawing-rules'
import Drawings from './drawings'
import ExternalPartners from './externalPartners'
import ExternalPaymentMethods from "./externalPaymentMethods"
import Fillins from './fillins'
import FuelScheduledDiscounts from './fuelScheduledDiscounts'
import Fuels from './fuels'
import GroupRule from './groupRules'
import MarketingCampaigns from './marketing'
import Messages from './messages'
import News from './news'
import ParentFuels from './parentFuels'
import Partners from './partners'
import Payments from './payments'
import pendingPoints from './pendingPoints'
import PermissionGroups from './permissionGroups'
import Permissions from './permissions'
import LimitNumberFillin from './pointRules/limitNumberFillin'
import PointFillinInterval from './pointRules/pointFillinInterval'
import PointLimit from './pointRules/pointLimit'
import PotencialFraud from './potencialFraud'
import Products from './products'
import ProductsCategory from './productsCategory'
import Profiles from './profiles'
import promoters from './promoters'
import Ratings from './ratings'
import Retailers from './retailers'
import SaleProducts from './saleProducts'
import Services from './services'
import settings from './settings'
import StationGroups from './stationGroups'
import Stations from './stations'
import StoresRating from './storesRatings'
import Tag from "./tag"
import UnredeemCoupon from './unredeemCoupon'
import Users from './users'
import ValidateCoupon from './validateCoupon'
import systemUsers from './systemUsers'
import ratingPollItems from './ratingPollItems'
import Gifts from './gifts'
import BulkSends from './bulkSend'
import generateConfirmationQrcode from './generateConfirmationQrcode'
import attendantGoalBoards from './attendantGoalBoards'

export interface Resource {
  name: string
  permissions: string[]
  list?: ComponentType
  create?: ComponentType
  edit?: ComponentType
  show?: ComponentType
  icon?: ComponentType
  options?: { label: string }
  hasShow: boolean
  hasEdit: boolean
}

export default [
  Chains,
  Users,
  PlacesUsers,
  Clients,
  Partners,
  Products,
  Stations,
  ValidateCoupon,
  CouponRefund,
  UnredeemCoupon,
  ExternalPartners,
  Messages,
  News,
  Profiles,
  PotencialFraud,
  ProductsCategory,
  Permissions,
  PermissionGroups,
  UnifiedSend,
  DispatchMessage,
  CustomerGroup,
  GroupRule,
  pendingPoints,
  SaleProducts,
  ParentFuels,
  Fuels,
  StationGroups,
  Fuels,
  Ratings,
  StoresRating,
  Fillins,
  Services,
  Attendants,
  Payments,
  FuelScheduledDiscounts,
  CompaniesWithoutPoints,
  Drawings,
  BillingAlert,
  ExpirationAlert,
  Billings,
  BillingsPayment,
  MarketingCampaigns,
  DrawingRule,
  LimitNumberFillin,
  PointLimit,
  PointFillinInterval,
  Retailers,
  Tag,
  ExternalPaymentMethods,
  promoters,
  settings,
  discounts,
  attendantUsers,
  advertisements,
  systemUsers,
  ratingPollItems,
  Gifts,
  BulkSends,
  generateConfirmationQrcode,
  attendantGoalBoards,
] as Resource[]
