import { PERMISSIONS } from '../../constants';
import list from './AttendantGoalBoardList';
import create from './AttendantGoalBoardCreate';
import show from './AttendantGoalBoardShow';

export default {
  list,
  create,
  show,
  name: 'attendant-goal-boards',
  permissions: [PERMISSIONS.ATTENDANT_TARGET]
}