import React from 'react';
import { TextInput } from 'react-admin';

const FormFrameInfo: React.FC<{ formData?: any }> = ({ formData }) => {

  return (
    <>
      <TextInput source="name" label="Nome do quadro" required fullWidth />
    </>
  );
}

export default FormFrameInfo;