import {
  BooleanInput,
  Create,
  FormTab,
  ImageField,
  ImageInput,
  PasswordInput,
  TabbedForm,
  TextInput,
  email,
  minLength,
  required
} from 'react-admin';
import { PhoneInput } from '../../components/Inputs';
import { Divider as MuiDivider, Grid, Typography, styled } from '@mui/material';
import { Permission, PriceInput } from '../../components';
import { PERMISSIONS } from '../../constants';
import { WrapperDotz } from './ChainEdit';

const validateName = [required(), minLength(2)]
const validateRequired = [required()]
const validateEmail = [required(), email()]
const validatePassword = [required(), minLength(6)];

const validateCredentials = credentials =>
  credentials &&
  credentials.host &&
  credentials.port &&
  credentials.database &&
  credentials.user &&
  credentials.password

const choices = [
  { id: 'utf-8', name: 'UTF-8' },
  { id: 'sql_ascii', name: 'SQL_ASCII' },
]

const Divider = styled(MuiDivider)({
  width: '100%',
  marginTop: 12,
  marginBottom: 12,
});

export default props => (
  <Create {...props} resource="chains" title="Cadastrar nova rede">
    <TabbedForm sanitizeEmptyValues>
      <FormTab label="Identificação">
        <TextInput source="name" label="Nome da rede/posto" validate={validateName} InputProps={{ autoComplete: 'off' }} />
        <PhoneInput
          InputProps={{ autoComplete: 'off' }}
          source="phone"
          label="Telefone"
          validate={validateRequired}
        />
        <TextInput source="email" label="Email" validate={validateEmail} InputProps={{ autoComplete: 'off' }} />
        <TextInput source="contact" label="Contato" InputProps={{ autoComplete: 'off' }} />
        <TextInput source="emailNotice" label="Email para Avisos" validate={email()} InputProps={{ autoComplete: 'off' }} />
        <ImageInput
          source="logo"
          label="Logo"
          accept="image/*"
          validate={validateRequired}
        >
          <ImageField source="src" title="Logo" />
        </ImageInput>
      </FormTab>
      <FormTab label="Usuário">
        <TextInput source="username" label="Nome de Usuário" validate={[required()]} InputProps={{ autoComplete: 'off' }} />
        <TextInput source="login" label="Login" validate={[required()]} InputProps={{ autoComplete: 'off' }} />
        <TextInput source="password" label="Senha" validate={validatePassword} InputProps={{ autoComplete: 'new-password' }} />
      </FormTab>
      {
        Permission({
          permission: PERMISSIONS.REGISTER_CHAIN_MODULE,
          children: (
            <FormTab label='Módulos'>
              <Grid container lg={8} md={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    QrCode
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PriceInput source="qrcodeValue" label="Valor" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BooleanInput source="useQrcode" label="Habilitar" />
                </Grid>
              </Grid>
              <Divider />
              <Grid container lg={8} md={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Promoter
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PriceInput source="promoterValue" label="Valor" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BooleanInput source="usePromoter" label="Habilitar" />
                </Grid>
              </Grid>
              <Divider />
              <Grid container lg={8} md={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Anúncios
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PriceInput source="advertisementValue" label="Valor" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BooleanInput source="useAdvertisement" label="Habilitar" />
                </Grid>
              </Grid>
              <Divider />
              <Grid container lg={8} md={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Sorteios
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PriceInput source="drawingValue" label="Valor" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BooleanInput source="useDrawing" label="Habilitar" />
                </Grid>
              </Grid>
              <Divider />
              <Grid container lg={8} md={12} spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography>
                    Brinde
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PriceInput source="giftValue" label="Valor" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BooleanInput source="useGift" label="Habilitar" />
                </Grid>
              </Grid>
              <Divider />
              <Grid container lg={8} md={12} spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography>
                    Dotz
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PriceInput source="dotzValue" label="Valor" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BooleanInput source="useDotz" label="Habilitar" />
                </Grid>
              </Grid>
              <Divider />
              <Grid container lg={8} md={12} spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Typography>
                    Meta de frentista
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PriceInput source="attendantTargetValue" label="Valor" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BooleanInput source="useAttendantTarget" label="Habilitar" />
                </Grid>
              </Grid>
            </FormTab>
          )
        })
      }
      <FormTab label='Configurações'>
        <TextInput source="firebaseProjectId" label="Id do Projeto no Firebase" InputProps={{ autoComplete: 'do-not-autofill' }} />
        <TextInput source="googlePackageName" label="Nome do Pacote no Google" InputProps={{ autoComplete: 'do-not-autofill' }} />
        <TextInput source="applePackageName" label="Nome do Pacote na Apple" InputProps={{ autoComplete: 'do-not-autofill' }} />
        <TextInput source="appleAppId" label="ID do Aplicativo na Apple" InputProps={{ id: 'field1', autoComplete: 'cc-exp' }} />
        <BooleanInput label="Habilitar acréscimo" source="isPriceIncreaseEnabled" />
        <BooleanInput label="Habilitar descontos múltiplos" source="isMultipleDiscountsEnabled" />
        <BooleanInput label="Usa Twilio" source="useTwilio" />
        <BooleanInput label="Usa Limites de Pontuação" source="useLimits" />
        <BooleanInput label="Conferir localização no abastecimento" source="requireLocationOnFillin" />
        <BooleanInput label="Somente encher tanque" source="appOnlyCompletelyFillin" />
      </FormTab>
      <WrapperDotz>
        <FormTab label="Dotz">
          <TextInput source="dotzUser" label="Usuário" sx={{ minWidth: 300 }} />
          <PasswordInput source="dotzPassword" label="Senha" inputProps={{ autocomplete: 'current-password' }} sx={{ minWidth: 300 }} />
          <TextInput
            source="dotzFactor"
            label="Fator de conversão"
            defaultValue={0}
            format={value => (!value ? '0,000' : `${Number(value).toFixed(3)}`)}
            parse={value => (!value ? 0 : +value.replace(/[^\d]/g, '') / 1000)}
          />
        </FormTab>
      </WrapperDotz>
    </TabbedForm>
  </Create>
)
