import React, { Fragment, useState } from 'react';
import {
  SimpleForm,
  Toolbar,
  SaveButton,
  required,
  useNotify,
  minLength,
} from 'react-admin';
import { useDataProvider } from 'react-admin';
import QrCode from 'qrcode.react';
import { makeStyles } from '@mui/styles';
import QrcodeIcon from '@mui/icons-material/QrCode';
import { Typography } from '@mui/material';
import { PhoneInput } from '../../components';

const CustomToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.invalid} label="Gerar qrcode" icon={<QrcodeIcon />} />
    </Toolbar>
  )
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    marginTop: '30px',
    padding: '20px',
  },
  qrcodeImageContainer: {
    minWidth: 200,
  },
});

const GenerateConfirmationQrcode = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [qrcodeInfo, setQrcodeInfo] = useState<{ deviceUniqueId: string } | null>(null);

  const styles = useStyles();

  const executeSearch = async ({ cellphone }: any) => {
    try {
      const response = await dataProvider.create('chains/chainId/customers/confirmation/generate-qrcode', { data: { cellphone: cellphone.slice(2) } })
      setQrcodeInfo(response.data);
    } catch (error: any) {
      notify(error?.message || 'Não foi possível gerar o qrCode', { type: 'warning' });
    }
  };

  return (
    <Fragment>
      <SimpleForm toolbar={<CustomToolbar />} onSubmit={executeSearch}>
        <PhoneInput source="cellphone" label="Telefone" validate={[required(), minLength(13)]} />
      </SimpleForm>
      {qrcodeInfo && (
        <div className={styles.container}>
          <div className={styles.qrcodeImageContainer}>
            <Typography variant="h6" textAlign="center" sx={{ fontWeight: '600' }} gutterBottom>Qrcode de confirmação</Typography>
            <QrCode
              renderAs="svg"
              value={qrcodeInfo.deviceUniqueId}
              bgColor="#ffffff"
              fgColor="#000000"
              width={256}
              height={256}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default GenerateConfirmationQrcode;
