import React, { cloneElement, ReactElement, useState } from 'react';
import { useGetOne, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

export enum ModuleKey {
  QRCODE = 'useQrcode',
  PROMOTER = 'usePromoter',
  LIMITS = 'useLimits',
  ADVERTISEMENT = 'useAdvertisement',
  GIFT = 'useGift',
  DOTZ = 'useDotz',
  ATTENDANT_TARGET = 'useAttendantTarget',
}

interface CheckChainUsesModuleProps {
  children: ReactElement;
  module: ModuleKey;
  alwaysOn?: boolean;
}

const CheckChainUsesModule: React.FC<CheckChainUsesModuleProps> = ({ children, module, ...props }) => {
  const [chainId] = useState(getChainId());
  if (!chainId) { return <></>; }

  const { data: chain } = useGetOne('chains', { id: chainId });
  const { permissions } = usePermissions();
  const isAdmin = permissions && permissions.includes(PERMISSIONS.ADMIN);

  return ((chain && chain[module] === true) || isAdmin) ? cloneElement(children, props) : null;
}
export default CheckChainUsesModule;
