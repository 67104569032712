import { useState } from 'react';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';

import { Typography } from '@mui/material';
import { getChainId, getUserId } from '../../lib';
import { ArrayField, ChipField, Datagrid, DateField, FunctionField, NumberField, ReferenceArrayField, ReferenceField, SelectField, Show, SimpleShowLayout, SingleFieldList, Tab, TabbedShowLayout, TextField } from 'ra-ui-materialui';
import { useListContext, usePermissions, useRecordContext } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { AttendantGoal, AttendantGoalType } from '../../models';
import CloneAttendantGoalBoardButton from './CloneAttendantGoalBoardButton';
import DisableAttendantGoalBoardButton from './DisableAttendantGoalBoardButton';
import attendantGoalBoards from '.';
import { AttendantGoalBoardDaysOfWeek } from '../../models/attendantGoal';

const SingleFieldChips = (props) => {
    const { data } = useListContext();
    return (
        !data || !data.length ?
            <FunctionField render={() => props.emptyText || 'Sem informações'} />
            :
            <SingleFieldList>
                <ChipField source={props.source} />
            </SingleFieldList>
    );
};

const StationListField = (props) => {
    const record = useRecordContext();
    const classes = useStyles();
    if (!record) { return null; }

    return (
        <SimpleShowLayout className={classes.containerChip} record={record}>
            <ReferenceArrayField label="Postos" reference="stations" source="stationIds">
                <SingleFieldChips source="name" emptyText="Sem postos" />
            </ReferenceArrayField>
        </SimpleShowLayout>
    );
}

const FuelListField = (props) => {
    const record = useRecordContext();
    const classes = useStyles();
    if (!record) { return null; }

    return (
        <SimpleShowLayout className={classes.containerChip} record={record}>
            <ReferenceArrayField label="Combustíveis" reference="fuels" source="fuelIds">
                <SingleFieldChips source="name" emptyText="Sem combustíveis" />
            </ReferenceArrayField>
        </SimpleShowLayout>
    );
}

const DayOfWeekField = (props) => {
    const record = useRecordContext();
    const classes = useStyles();
    if (!record) { return null; }

    return (
        <SimpleShowLayout className={classes.containerChip} record={{ id: record.id, daysOfWeek: AttendantGoal.convertScheduleBooleanToDaysOfWeek(record).reduce<any>((acc, day) => ([...acc, { name: AttendantGoalBoardDaysOfWeek.translateType(day) }]), []) }}>
            <ArrayField source="daysOfWeek" label="Dias da semana">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" label="Dia" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    );
}

const AttendantTargetList = (props) => {

    const { permissions } = usePermissions();

    const [chainId] = useState(getChainId());
    if (!chainId) { return <Typography>Selecione uma Rede</Typography>; }

    return (
        <Permission permission={PERMISSIONS.ATTENDANT_TARGET}>
            <Show title='Quadro' {...props}>
                <TabbedShowLayout>
                    <Tab label="Resumo">
                        <DisableAttendantGoalBoardButton style={{ marginTop: 20 }} isOutlined />
                        <CloneAttendantGoalBoardButton variant="outlined" style={{ marginBottom: 10 }} />
                        <TextField source="code" label="Código" />
                        <TextField source="name" label="Nome" />
                        <ReferenceField source="createdByEmployeeId" reference="employees" label="Criado por" link={permissions && permissions.includes(PERMISSIONS.UPDATE_USER)}>
                            <FunctionField render={record => record ? `${record.name} ${record.id === getUserId() ? '(você)' : ''}` : ''} />
                        </ReferenceField>
                        <StationListField />
                        <FuelListField />
                        <DateField source="startDate" label="Inicio em" textAlign="center" emptyText="--" />
                        <DateField source="endDate" label="Fim em" textAlign="center" emptyText="--" />
                        <DayOfWeekField />
                        <DateField source="disabledAt" label="Desativado em" showTime textAlign="center" emptyText="--" />
                    </Tab>
                    <Tab label="Metas">
                        <ArrayField source="targets" label="Metas">
                            <Datagrid bulkActionButtons={false}>
                                <SelectField
                                    source="type"
                                    label="Tipo"
                                    choices={AttendantGoalType.attendantGoalTypesChoices}
                                />
                                <NumberField
                                    source="minimumAmount"
                                    label="Mínimo"
                                    emptyText="0,00"
                                    options={{
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }}
                                    textAlign="center"
                                />
                                <NumberField
                                    source="fixedPrice"
                                    label="R$ fixo"
                                    emptyText="R$ 0,00"
                                    options={{
                                        style: 'currency',
                                        currency: 'BRL',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }}
                                    textAlign="center"
                                />
                                <NumberField
                                    source="additionalUnit"
                                    label="Unid. adicional"
                                    emptyText="--"
                                    options={{
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }}
                                    textAlign="center"
                                />
                                <NumberField
                                    source="additionalPrice"
                                    label="R$ adicional"
                                    emptyText="--"
                                    options={{
                                        style: 'currency',
                                        currency: 'BRL',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }}
                                    textAlign="center"
                                />
                            </Datagrid>
                        </ArrayField>
                    </Tab>
                </TabbedShowLayout>
            </Show>
        </Permission>
    );
};

const useStyles = makeStyles((theme) => ({
    containerChip: {
        padding: 0,
    },
}));

export default AttendantTargetList;
