import { CreateButton, Datagrid, DateField, Filter, FunctionField, List, ReferenceArrayInput, ReferenceInput, SelectArrayInput, SelectInput, TextField } from 'ra-ui-materialui';
import { CheckChainUsesModule, ModuleKey } from '../../components';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';
import { Box, Typography } from '@mui/material';
import { FormDataConsumer } from 'react-admin';
import CloneAttendantGoalBoardButton from './CloneAttendantGoalBoardButton';

const AttendantTargetList = (props) => {

    const filterDefaultValues: any = { isDisabled: false };
    const chainId = getChainId();

    if (chainId) {
        filterDefaultValues.chainId = chainId;
    } else {
        return (
            <Box>
                <Typography>Selecione uma rede para visualizar as informações</Typography>
            </Box>
        );
    }

    const ListFilters = () => (
        <Filter>
            <SelectInput
                label="Status"
                source="isDisabled"
                choices={[{ label: 'Ativos', value: false }, { label: 'Inativos', value: true }]}
                optionText="label"
                optionValue="value"
                emptyText="Todos"
                alwaysOn
            />
            {
                Permission({
                    permission: PERMISSIONS.ADMIN, children:
                        <ReferenceInput
                            reference="chains"
                            source="chainId"
                            style={{ minWidth: 220 }}
                            defaultValue={getChainId()}
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={9999}
                        >
                            <SelectInput label="Rede" optionText="name" />
                        </ReferenceInput>
                })
            }
            <FormDataConsumer alwaysOn>
                {({ formData }) => (
                    <ReferenceArrayInput
                        reference="stations"
                        source="stationIds"
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={9999}
                        style={{ minWidth: 220, marginBottom: -16 }}
                        filter={formData.chainId ? { chainId: formData.chainId } : {}}
                    >
                        <SelectArrayInput label="Postos" optionText="name" />
                    </ReferenceArrayInput>
                )}
            </FormDataConsumer>
            <ReferenceArrayInput
                reference="fuels"
                source="fuelIds"
                style={{ minWidth: 220 }}
                sort={{ field: "name", order: "ASC" }}
                alwaysOn
            >
                <SelectArrayInput label="Combustíveis" optionText="name" />
            </ReferenceArrayInput>
        </Filter>
    );

    return (
        <Permission permission={PERMISSIONS.ATTENDANT_TARGET}>
            <CheckChainUsesModule module={ModuleKey.ATTENDANT_TARGET}>
                <List
                    {...props}
                    title="Quadros"
                    sort={{ field: 'createdAt', order: 'DESC' }}
                    filterDefaultValues={filterDefaultValues}
                    filters={<ListFilters />}
                    exporter={false}
                    actions={<CreateButton />}
                    disableSyncWithLocation
                >
                    <Datagrid bulkActionButtons={false} rowClick="show">
                        <TextField source="code" label="Código" />
                        <TextField source="name" label="Nome" />
                        <DateField source="startDate" label="Data de início" textAlign="center" emptyText="--" />
                        <DateField source="endDate" label="Data de fim" textAlign="center" emptyText="--" />
                        <FunctionField
                            render={record => record && record.isDisabled ? "Inativo" : "Ativo"}
                            label="Status"
                            textAlign="center"
                        />
                        <DateField source="disabledAt" label="Desativado em" showTime textAlign="center" emptyText="--" />
                        <CloneAttendantGoalBoardButton />
                    </Datagrid>
                </List>
            </CheckChainUsesModule>
        </Permission>
    );
};

export default AttendantTargetList;
