export enum AttendantGoalType {
  QUANTITY = "quantity",
  VOLUME = "volume",
  PERCENTAGE_LOYALTY = "percentage-loyalty",
  FULL_TANK = "full-tank"
}

export namespace AttendantGoalType {
  export const translateType = (type: AttendantGoalType): string => {
    switch (type) {
      case AttendantGoalType.QUANTITY:
        return "Quantidade"
      case AttendantGoalType.VOLUME:
        return "Volume";
      case AttendantGoalType.PERCENTAGE_LOYALTY:
        return "% Fidelidade";
      case AttendantGoalType.FULL_TANK:
        return "Tanque cheio";
      default: return "";
    };
  };

  export const attendantGoalTypesChoices = Object.values(AttendantGoalType).filter(x => typeof x === "string")
    .map(type => {
      return ({
        id: type,
        name: AttendantGoalType.translateType(type as any)
      })
    });
};
