import { makeStyles } from '@mui/styles';
import React from 'react';
import { CheckboxGroupInput, DateInput, required, TextInput } from 'react-admin';
import { AttendantGoalBoardDaysOfWeek } from '../../../models/attendantGoal';
import dayjs from 'dayjs';

const FormSchedule: React.FC<{ formData?: any }> = ({ formData }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.dateTimePicker}>
        <DateInput
          source="startDate"
          label="Data inicial"
          validate={[required()]}
          parse={value => value ? dayjs(value).startOf('day').toISOString() : null}
        />
        <DateInput
          source="endDate"
          label="Data final"
          validate={[required()]}
          parse={value => value ? dayjs(value).startOf('day').toISOString() : null}
        />
      </div>

      <CheckboxGroupInput
        source="daysOfWeek"
        label="Dias da semana"
        choices={AttendantGoalBoardDaysOfWeek.attendantGoalBoardDaysOfWeekChoices}
        validate={[required()]}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  dateTimePicker: {
    display: 'flex',
    gap: 20,
  },
}));

export default FormSchedule;