import { useState } from 'react';
import {
  Button, useDataProvider,
  useRecordContext, useRefresh
} from 'react-admin';

const DisableAttendantGoalBoardButton = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const record = useRecordContext();
  if (!record) { return null; }

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      {...props}
      disabled={record?.isDisabled || isLoading}
      label={!record?.isDisabled ? "Desativar quadro" : "Quadro desativado"}
      variant={props.isOutlined ? "outlined" : undefined}
      onClick={() => {
        setIsLoading(true);
        dataProvider.update('attendant-goal-boards', { id: record.id, data: { isDisabled: true }, previousData: { ...record } })
          .then(() => {
            refresh();
          })
          .catch(() => console.log("Erro na mensagem"))
          .finally(() => setIsLoading(false));
      }}
    />
  );
};

export default DisableAttendantGoalBoardButton;