import {
  Confirm,
  Create,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  minValue,
  required,
  useGetList,
  useNotify,
  useRefresh
} from 'react-admin';
import { useSaveContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { LazyLoadAutoCompleteInput, PriceInput, Wrapper } from '../../components';
import { CPFInput } from '../../components/Inputs';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId } from '../../lib';
import { Alert, Grid } from '@mui/material';
import { formatAttendantLabel, formatNumber } from '../../utils';
import { useState } from 'react';

const HoseInpunt = () => (
  <FormDataConsumer>
    {({ formData }) => {

      if (!formData.placeId || !formData.fuelId) { return null; }

      const { data: hoseNumber, isLoading: isPendingHose, isError } = useGetList(`stations/${formData.placeId}/hose-numbers`, { filter: { fuelId: formData.fuelId } });

      if (!isPendingHose && isError) { return null; }

      return (
        <Grid container spacing={2}>
          <Grid item xl={2} lg={3} md={3}>
            <RadioButtonGroupInput
              source="hoseNumber"
              optionText="hoseNumber"
              label="Bico"
              choices={hoseNumber || []}
              isLoading={isPendingHose}
              fullWidth
            />
          </Grid>
        </Grid>
      )
    }}
  </FormDataConsumer>
);

const PointsCreateToolbar = () => {

  const [confirmDialog, setConfirmDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const form = useFormContext();
  const saveContext = useSaveContext();

  const verifySubmit = async (value) => {
    if (value.fillinAmount > 0) {
      const pricePerLiter = +(value.fillinValue / value.fillinAmount).toFixed(3);
      if (pricePerLiter < 1 || pricePerLiter > 10) {
        setConfirmDialog(true);
        return;
      }
    }
    form.handleSubmit(handleSubmit)();
  }

  const handleSubmit = async (value) => {
    saveContext.save && await saveContext.save(value, {
      onSuccess: () => {
        form.reset();
        window.scrollTo(0, 0);
        notify('ra.notification.created', {
          type: 'info',
          messageArgs: { smart_count: 1 },
        });
        refresh();
      },
    });
  }

  const handleConfirmDialog = () => {
    setConfirmDialog(false);
    form.handleSubmit(handleSubmit)();
  }

  const handleCloseDialog = () => {
    setConfirmDialog(false);
  }

  return (
    <Toolbar>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <SaveButton
              type="button"
              label="ra.action.save"
              variant="text"
              onClick={form.handleSubmit(verifySubmit)}
            />
            <Confirm
              isOpen={confirmDialog}
              title="Atenção"
              content={
                <Alert
                  variant="outlined"
                  severity="warning"
                >
                  O preço por litro é <strong>{formatNumber(+(formData.fillinValue / formData.fillinAmount).toFixed(3), { style: 'currency', currency: 'BRL', minimumFractionDigits: 3, maximumFractionDigits: 3 })}</strong> <br />
                  Este valor parece incorreto. Tem certeza que deseja continuar?
                </Alert>
              }
              onConfirm={handleConfirmDialog}
              onClose={handleCloseDialog}
            />
          </>
        )}
      </FormDataConsumer>
    </Toolbar>
  );
};

const sanitize = (data) => {
  return { ...Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null)) };
};

export default props => {
  return (
    <Wrapper>
      <Permission permission={PERMISSIONS.ADD_POINTS}>
        <Create {...props} title="Adicionar Pontos" resource={`chains/${getChainId()}/addition-points`} transform={sanitize}>
          <SimpleForm sanitizeEmptyValues toolbar={<PointsCreateToolbar />} defaultValues={{ placeId: getPlaceId() }}>
            <Grid container spacing={2}>
              <Grid item xl={2} lg={3} md={3}>
                <NumberInput
                  source="couponInvoice"
                  label="Cupom Fiscal"
                  validate={[required("Cupom Fiscal é obrigatório"), minValue(1)]}
                  min={1}
                  fullWidth
                />
              </Grid>
              <Grid item xl={2} lg={3} md={3}>
                <TextInput
                  source="couponSerialNumber"
                  label="Número de Série"
                  validate={required("Número de Série é obrigatório")}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xl={2} lg={3} md={3}>
                <CPFInput source="customerCpf" label="CPF" isRequired fullWidth />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {!getPlaceId() && (
                <Grid item xl={2} lg={3} md={3}>
                  <ReferenceInput
                    source="placeId"
                    filter={{ chainId: getChainId() }}
                    perPage={9999}
                    reference="stations"
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={required("Posto é obrigatório")}
                  >
                    <SelectInput
                      label="Posto"
                      optionText="name"
                      fullWidth
                    />
                  </ReferenceInput>
                </Grid>)
              }
              <Grid item>
                <FormDataConsumer>
                  {({ formData }) => formData.placeId &&
                    //  @ts-ignore
                    <LazyLoadAutoCompleteInput
                      label="Frentista"
                      source="attendantId"
                      format={record => formatAttendantLabel(record)}
                      style={{ minWidth: 205 }}
                      reference="attendants"
                      filter={{ placeId: formData.placeId }}
                    />
                  }
                </FormDataConsumer>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xl={2} lg={3} md={3}>
                <TextInput
                  source="fillinAmount"
                  label="Litros"
                  defaultValue="0"
                  format={(value) => {
                    if (!value || value === 0) {
                      return "0,00";
                    }
                    return `${Number(value).toFixed(2)}`;
                  }}
                  parse={(value) => {
                    if (!value || value === '') {
                      return 0;
                    }
                    const onlyNumbers = Number(value.replace(/[^\d]/g, ''));
                    return onlyNumbers / 100;
                  }}
                  validate={[required("Litros é obrigatório"), minValue(1)]}
                  fullWidth
                />
              </Grid>
              <Grid item xl={2} lg={3} md={3}>
                <PriceInput source="fillinValue" label="Valor" precision={2} validate={[required("Valor é obrigatório"), minValue(0.01)]} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xl={2} lg={3} md={3}>
                <DateTimeInput
                  source="createdAt"
                  label="Data e Hora"
                  fullWidth
                />
              </Grid>
            </Grid>
            <FormDataConsumer>
              {({ formData }) => formData.placeId &&
                <Grid container spacing={2}>
                  <Grid item xl={2} lg={3} md={3}>
                    <ReferenceInput
                      source="fuelId"
                      filter={{ placeId: formData.placeId, hasFactor: true }}
                      reference="fuels"
                      sort={{ field: 'name', order: 'ASC' }}
                    >
                      <SelectInput
                        validate={required("Combustível é obrigatório")}
                        label="Combustível"
                        optionText="name"
                        fullWidth
                      />
                    </ReferenceInput>
                  </Grid>
                </Grid>
              }
            </FormDataConsumer>
            <HoseInpunt />
            <Grid container spacing={2}>
              <Grid item xl={2} lg={3} md={3}>
                <NumberInput
                  source="encerrante"
                  label="Encerrante"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xl={2} lg={3} md={3}>
                <TextInput source="description" label="Observação" multiline fullWidth />
              </Grid>
            </Grid>
          </SimpleForm>
        </Create>
      </Permission>
    </Wrapper>
  )
}
