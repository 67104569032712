import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  Labeled,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  minValue,
  useNotify
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { ImageInformations, PhoneInput, PriceInput, Wrapper } from '../../components';
import Permission from '../../components/Permission';
import { RichTextInputCustom } from '../../components/RichTextInputCustom';
import { PERMISSIONS } from '../../constants';
import { getChainId } from '../../lib';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    marginTop: 40,
  },
  referralContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 40,
  },
  integrationContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 40,
  },
  labelImage: {
    fontFamily: 'Helvetica',
    fontSize: '12px',
    marginBottom: '8px',
    display: 'block'
  },
  cardsSpoiler: {
    width: '150%',
    maxWidth: 500,
    paddingInline: 8,
    marginBottom: 16
  },
  imageCard: {
    marginTop: 16,
    '& img': {
      maxWidth: '100%',
    }
  },
}));

const ImagePrev = ({ label, className, imagePath }) => (
  <Labeled label={label} className={className} >
    <img src={imagePath} />
  </Labeled>);

export default () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const notify = useNotify();

  const orderCards = (settings) => {
    if (!settings.firebaseConfig.home_cards) { return settings; }
    const cardsArr = settings.firebaseConfig.home_cards;

    if (cardsArr && cardsArr.length) {
      cardsArr.forEach((card, index) => {
        card.index = index;
        if (card.imagefile) {
          card.updateImage = true;
        }
      });
    }

    return settings;
  };

  const navigationChoices = [
    { id: '', name: 'Nenhum' },
    { id: 'Coupons', name: 'Cupons' },
    { id: 'AccountManagement', name: 'Detalhes da Conta' },
    { id: 'DotzDiscounts', name: 'Descontos Dotz' },
    { id: 'History', name: 'Extrato' },
    { id: 'ReferralCode', name: 'Indique e Ganhe' },
    { id: 'Notifications', name: 'Notificações' },
    { id: 'GasStations', name: 'Postos' },
    { id: 'PlacesSelect', name: 'Resgatar' },
    { id: 'Drawings', name: 'Sorteios' },
    { id: 'Term', name: 'Termos de Uso' },
  ];

  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    navigate('/settings');
  }

  const onError = () => {
    notify('ra.notification.data_provider_error', { type: 'error' });
    navigate('/settings');
  }

  return (
    <Permission permission={PERMISSIONS.SETTINGS}>
      {
        getChainId() ?
          <Edit
            id={getChainId()}
            resource="chains-config"
            title="Configurações"
            mutationMode='pessimistic'
            mutationOptions={{ onSuccess, onError }}
            transform={(props) => {
              return orderCards(props);
            }}
          >
            {/* <TabbedForm toolbar={<CustomToolbar />}> */}
            <TabbedForm>
              <FormTab label="Geral">
                <ReferenceInput
                  source="defaultStationId"
                  reference="stations"
                  sort={{ field: 'name', order: 'ASC' }}
                  style={{ minWidth: 290 }}
                  perPage={9999}
                >
                  <SelectInput
                    label="Posto padrão para vínculo de pontos"
                    optionText="name" />
                </ReferenceInput>
                <NumberInput
                  source="pointsExpirationTime"
                  label="Tempo para expiração de pontos (dias)"
                  style={{ minWidth: 290 }}
                  min={0}
                />
                <PriceInput source="cashbackFactor" label="Valor do cashback para 1 ponto" precision={3} style={{ minWidth: 290 }} />
                <TextInput
                  source="mex10Token"
                  label="Token Mex10"
                  style={{ minWidth: 290 }}
                />
                <BooleanInput label="Pontuar quando usar desconto" source="pointsWhenDiscounting" />
                <BooleanInput label="Cadastrar senha automática para o cliente(6 primeiros dígitos do CPF)" source="useGeneratedDefaultPassword" />
                <BooleanInput label="Ativar cronologia dos cupons" source="useChronologyCouponInvoice" />
                <BooleanInput label="Resgate somente após um abastecimento" source="useOnlyRedeemAfterFillin" />
                <BooleanInput label="Usar data do concentrador nos relatórios" source="useDateConcentrator" />
                <BooleanInput label="Solicitar aprovação para abastecimento manual" source="usePendingTransaction" />
              </FormTab>
              <FormTab label="Regulamento">
                <RichTextInputCustom source="regulation" label="Regulamento" />
              </FormTab>
              <FormTab label="Indicação">
                <NumberInput
                  source="referralValue"
                  label="Valor para quem indica"
                  min={0}
                  style={{ width: 300 }}
                />
                <NumberInput
                  source="refereeValue"
                  label="Valor para indicado"
                  min={0}
                  style={{ width: 300 }}
                />
                <Typography fullWidth>Crie o seu texto de indicação. A palavra "referral_code" será substituída pelo código do usuário.</Typography>
                <TextInput
                  label="Texto de Indicação"
                  source="firebaseConfig.referral_message"
                  fullWidth
                  multiline
                  defaultValue={"Ganhe desconto ao abastecer! Use o meu código referral_code e ganhe ainda mais pontos ao utilizar o app. Garanta a qualidade e quantidade do combustível e troque pontos por produtos e serviços."} />
              </FormTab>
              <FormTab label="Aplicativo">
                <Accordion className={classes.cardsSpoiler}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Cards na Tela Inicial do App</Typography>
                  </AccordionSummary>
                  <ArrayInput label="É necessário reabrir o App para visualizar as alterações" source="firebaseConfig.home_cards">
                    <SimpleFormIterator style={{ margin: 8 }}>
                      <FormDataConsumer>
                        {({ getSource, scopedFormData }) => (
                          <>
                            <ImagePrev
                              label="Imagem Atual"
                              className={classes.imageCard}
                              {...scopedFormData}
                            />
                            <ImageInput label="Nova Imagem" record={scopedFormData} source={getSource('imagefile')} accept="image/*">
                              <ImageField source="src" />
                            </ImageInput>
                            <ImageInformations position={-20} />
                            <SelectInput
                              label="Metodo de Navegação"
                              source={getSource('navigation.method')}
                              choices={[
                                { id: 'screen', name: 'Tela' },
                                { id: 'webview', name: 'Site Externo' },
                                { id: 'whatsapp', name: 'Whatsapp Externo' },
                              ]}
                              defaultValue="screen"
                              style={{ width: '100%' }}
                            />
                          </>
                        )}
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                          switch (scopedFormData?.navigation?.method) {
                            case 'screen':
                              return (
                                <SelectInput
                                  label="Destino da Navegação"
                                  style={{ width: '100%' }}
                                  source={getSource('navigation.destination')}
                                  choices={navigationChoices}
                                  defaultValue=""
                                />
                              );
                            case 'whatsapp':
                              return (
                                <>
                                  <PhoneInput label="Número de Destino" record={scopedFormData} source={getSource('navigation.destination')} style={{ width: '100%' }} />
                                  <TextInput label="Mensagem Inicial" record={scopedFormData} source={getSource('navigation.whatsappMessage')} style={{ width: '100%' }} />
                                </>
                              );
                            case 'webview':
                              return (
                                <TextInput label="URL de Destino" record={scopedFormData} source={getSource("navigation.destination")} style={{ width: '100%' }} />
                              );
                            default:
                              return null;
                          }
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Accordion>

                <BooleanInput label="Saldo em Cashback na Home do app" source="firebaseConfig.show_cashback_at_home" defaultValue={false} />
                <BooleanInput label="Mostrar avaliação do posto" source="firebaseConfig.should_show_station_rating" defaultValue={false} />
                <BooleanInput label="Usar preset no abastecimento" source="useFillinPreset" />
                <BooleanInput label="Botão de desconto ativado por padrão" source="firebaseConfig.discount_button_enabled" defaultValue />
                <BooleanInput label="Obrigar usuário a avaliar abastecimento" source="requireRatingOnFillin" />
                <BooleanInput label="Permitir desassociação de Promoter" source="allowPromoterUnlinking" />
                <BooleanInput label="Mostar descontos disponíveis na tela de cupons (Ofertas)" source="isOffersEnabledInCoupon"  />
                <TextInput label="Regulamento do Número da Sorte" source="firebaseConfig.raffle_regulation_url" />
              </FormTab>
              <FormTab label="Integração">
                <ArrayInput
                  source="fuelIntegrationRules"
                  label="Combustíveis"
                >
                  <SimpleFormIterator disableRemove disableReordering disableAdd>
                    <FormDataConsumer>
                      {({ getSource, scopedFormData }) => {
                        return (
                          <>
                            <Accordion style={{ width: '100%', marginBottom: 12 }}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <ReferenceField label="Combustível" link={false} record={scopedFormData} source="fuelId" reference='fuels'>
                                  <TextField source="name" style={{ fontSize: '16px' }} />
                                </ReferenceField>
                              </AccordionSummary>
                              <AccordionDetails>
                                <NumberInput
                                  source={getSource('amountToFillingCompletely')}
                                  record={scopedFormData}
                                  label="Considerar tanque cheio (L)"
                                  style={{ width: 280 }}
                                  validate={[minValue(0)]}
                                />
                              </AccordionDetails>
                            </Accordion>
                          </>
                        );
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </FormTab>
              <FormTab label="Login no aplicativo">
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={2} mr={{ xs: 0, sm: '1.5em' }}>
                    <NumberInput
                      label="Máximo de dispositivos por CPF"
                      source="maxDevicePerCpf"
                      min={1}
                      validate={[minValue(1)]}
                      parse={(value) => (isNaN(value) || value <= 0 ? null : Math.round(value))}
                      style={{ minWidth: 300 }}
                    />
                  </Box>
                  <Box flex={2}>
                    <NumberInput
                      label="Máximo de CPFs por dispositivo"
                      source="maxCpfPerDevice"
                      min={1}
                      validate={[minValue(1)]}
                      parse={(value) => (isNaN(value) || value <= 0 ? null : Math.round(value))}
                      style={{ minWidth: 300 }}
                    />
                  </Box>
                </Box>
              </FormTab>
            </TabbedForm>
          </Edit>
          :
          <Wrapper>
            <Typography>Selecione uma rede para fazer alterações</Typography>
          </Wrapper>
      }
    </Permission>
  );
};
