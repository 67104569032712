import { Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneElement, useContext, useMemo, useRef } from 'react';
import { BulkActionsToolbar, ExportButton, FilterButton, FilterContext, ListBase, ListToolbar, Pagination, Title, TopToolbar, sanitizeListRestProps, useListContext } from 'react-admin';
import { CreateBulkSendButton, CreateDiscountButton, CustomExportButton, CustomGenericButton, PrintButton, PrintSummaryButton, ReportPrint } from '../index';

const useStyles = makeStyles(theme => ({
  printButton: {
    alignSelf: 'center',
    height: '100%',
    verticalAlign: 'center',
  },
  filterTablePrint: {
    display: 'none',
    '@media print': {
      display: 'block'
    },
    marginBottom: 10,
  },
  contentPagination: {
    '@media print': {
      display: 'none'
    },
  },
  printContent: {
    display: 'none',
    '@media print': {
      display: 'block'
    },
  },
  titleCard: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

const ListShowActions = (props) => {
  const context = useListContext();
  const classes = useStyles();

  const {
    filters: filtersProp,
    showFilterButton,
    maxResults,
    reportRef,
    reportSummaryRef,
    isReport,
    exporter,
    customExporter,
    exportFile,
    disablePrint,
    disableSummaryPrint = true,
    discountButton,
    bulkSendButton,
    ...restFilters
  } = props;

  const filters = useContext(FilterContext) || filtersProp;

  return useMemo(
    () => (
      <TopToolbar sx={{ alignItems: 'center' }} {...sanitizeListRestProps(restFilters)}>
        {
          showFilterButton && (filtersProp
            ? cloneElement(filtersProp, {
              resource: context.resource,
              showFilter: context.showFilter,
              displayedFilters: context.displayedFilters,
              filterValues: context.filterValues,
              context: 'button',
            })
            :
            filters && <FilterButton />)
        }

        {exporter &&
          <ExportButton resource={props.resource} exporter={exporter || undefined} maxResults={maxResults} />
        }
        {customExporter &&
          <CustomExportButton preExport={customExporter} isReport={isReport} />
        }
        {exportFile &&
          <CustomGenericButton onExport={exportFile} />
        }
        {!disablePrint &&
          <PrintButton printRef={reportRef} className={classes.printButton} />
        }
        {!disableSummaryPrint &&
          <PrintSummaryButton reportSummaryRef={reportSummaryRef} className={classes.printButton} />
        }
        {
          discountButton &&
          <CreateDiscountButton />
        }
        {
          bulkSendButton &&
          <CreateBulkSendButton />
        }
      </TopToolbar>
    ),
    [
      restFilters,
      filtersProp,
      filters,
      showFilterButton,
      maxResults,
      isReport,
      reportRef,
      exporter,
      customExporter,
      exportFile,
      disablePrint,
      discountButton,
    ]
  );
}

const ReportList = ({ maxResults = 50000, children, actions, bulkActionButtons,
  filters, title, pagination, exporter, disablePrint, landscape, titleOnPrint, exportFile,
  filterTablePrint, showFilterLabel = true, showPagination = true, printContent: PrintContent,
  noPadding = false, customExporter, discountButton, bulkSendButton, isReport, showFilterButton, disableSummaryPrint, ...props }) => {
  const classes = useStyles();
  const reportRef = useRef();
  const reportSummaryRef = useRef();

  const ReportCard = () => (
    <Card>
      <BulkActionsToolbar>
        {bulkActionButtons}
      </BulkActionsToolbar>
      {
        cloneElement(children, {
          hasBulkActions: bulkActionButtons !== false,
        })
      }
      {showPagination === true &&
        <div className={classes.contentPagination}>
          {pagination ? pagination : <Pagination />}
        </div>
      }
    </Card>
  );

  return (
    <ListBase {...props}>
      <Title title={title} />
      <ListToolbar
        filters={filters}
        actions={actions ?
          cloneElement(actions, { reportRef, resource: props.resource })
          :
          <ListShowActions
            showFilterButton={showFilterButton}
            maxResults={maxResults}
            reportRef={reportRef}
            reportSummaryRef={reportSummaryRef}
            isReport={isReport}
            exporter={exporter}
            customExporter={customExporter}
            exportFile={exportFile}
            disablePrint={disablePrint}
            disableSummaryPrint={disableSummaryPrint}
            discountButton={discountButton}
            bulkSendButton={bulkSendButton}
          />
        }
      />
      {
        (PrintContent && !disableSummaryPrint) ?
          <>
            <ReportPrint noPadding={noPadding} ref={reportSummaryRef} title={titleOnPrint} landscape={landscape || false}>
              <div className={classes.printContent}>
                <PrintContent />
              </div>
            </ReportPrint>
            <ReportPrint noPadding={noPadding} ref={reportRef} title={titleOnPrint} landscape={landscape || false}>
              {filterTablePrint &&
                <div className={classes.filterTablePrint}>
                  {showFilterLabel && <Typography className={classes.titleCard}>Filtros</Typography>}
                  <Card>
                    {filterTablePrint}
                  </Card>
                </div>
              }
              <ReportCard />
            </ReportPrint>
          </>
          : PrintContent ?
            <>
              <ReportPrint noPadding={noPadding} ref={reportSummaryRef} title={titleOnPrint} landscape={landscape || false}>
                <PrintContent />
              </ReportPrint>
              <ReportCard />
            </>
            :
            <ReportPrint noPadding={noPadding} ref={reportRef} title={titleOnPrint} landscape={landscape || false}>
              {filterTablePrint &&
                <div className={classes.filterTablePrint}>
                  {showFilterLabel && <Typography className={classes.titleCard}>Filtros</Typography>}
                  <Card>
                    {filterTablePrint}
                  </Card>
                </div>
              }
              <ReportCard />
            </ReportPrint>
      }
    </ListBase>
  );
};

export default ReportList;